import { Component, OnInit, Input } from '@angular/core';
import { ECustomerMessageType, EMailTemplate } from '@caronsale/cos-models';
import { IUserNotificationPreferences } from '@caronsale/cos-models/dist/User/IGeneralUserSettings';
import { CosInternalClientService } from '@cosCoreServices/cos-internal-client/cos-internal-client.service';
import { finalize } from 'rxjs';

type EmailSettingsOptions = { label: string; checked: boolean };

@Component({
  selector: 'app-internal-user-email-settings',
  templateUrl: './internal-user-email-settings.component.html',
  styleUrls: ['./internal-user-email-settings.component.scss'],
})
export class InternalUserEmailSettingsComponent implements OnInit {
  @Input()
  public uuid: string;

  public templateTypesMap: EmailSettingsOptions[] = this.arrayToFlagMap(Object.values(ECustomerMessageType));
  public templateNamesMap: EmailSettingsOptions[] = this.arrayToFlagMap(Object.values(EMailTemplate));

  public loading = false;
  public userNotificationPreferences: IUserNotificationPreferences;

  public constructor(private cosInternalClientService: CosInternalClientService) {}

  public ngOnInit(): void {
    this.loadSettings();
  }

  public loadSettings(): void {
    this.loading = true;

    this.cosInternalClientService
      .getUserSettings(this.uuid)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(userSettings => {
        this.userNotificationPreferences = userSettings.notificationPreferences;

        this.templateTypesMap = this.arrayToFlagMap(
          Object.values(ECustomerMessageType),
          this.userNotificationPreferences?.disabledEmailTypesNotifications ?? [],
        );
        this.templateNamesMap = this.arrayToFlagMap(Object.values(EMailTemplate), this.userNotificationPreferences?.disabledEmailTemplatesNotifications ?? []);
      });
  }

  public saveSettings(): void {
    this.loading = true;

    this.cosInternalClientService
      .updateUserSettings(this.uuid, {
        notificationPreferences: {
          ...this.userNotificationPreferences,
          disabledEmailTypesNotifications: this.templateTypesMap.filter(({ checked }) => !checked).map(({ label }) => label) as ECustomerMessageType[],
          disabledEmailTemplatesNotifications: this.templateNamesMap.filter(({ checked }) => !checked).map(({ label }) => label) as EMailTemplate[],
        },
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe();
  }

  private arrayToFlagMap(list: string[], unchecked: string[] = []): EmailSettingsOptions[] {
    return list.sort().map(label => ({
      label,
      checked: !unchecked.includes(label),
    }));
  }
}
